<template>
    <form class="add-form">
        <div class="form-section">
            <label for="value">Libellé</label>
            <input type="text" v-model="body.label"/>
        </div>

        <div class="form-section">
            <label for="type">Produit</label>
            <select id="type" v-model="body.type">
                <option v-for="(value, key) in $store.getters.insuranceTypes" :key="key" :value="key">{{ capitalize(value) }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="owner">Souscripteur assuré</label>
            <select id="owner" v-model="owner" :disabled="!isOriginalScenario && !isNew">
                <option v-for="(value, index) in ownersList" :key="index" :value="value">{{ $store.getters.findOwnerName(value) }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="value">Date de souscription</label>
            <input type="date" v-model="body.opening_date" :disabled="!isOriginalScenario && !isNew"/>
        </div>

        <div class="form-section">
            <label>Valeur du bien</label>
            <currency-input v-model="body.value" :disabled="!isNew"/>
        </div>

        <div class="form-section" v-if="!isNew">
            <label for="value">Retrait ou versement pour la valeur</label>
            <value-update @valueUpdate="updateValue"/>
        </div>

        <div class="form-section">
            <label for="value">Versements totaux</label>
            <currency-input v-model="body.total_payment"/>
        </div>

        <div class="form-section" v-if="ownerAge >= 70">
            <label for="value">Versements après 70 ans</label>
            <currency-input v-model="body.payment_after70"/>
        </div>

        <div class="form-section" v-if="subscribedBefore1998">
            <label for="value">Versements avant le 13/12/1998</label>
            <currency-input v-model="body.payment_before1998"/>
        </div>

        <div class="form-section">
            <label for="value">Versements mensuels</label>
            <currency-input v-model="body.payment_monthly"/>
        </div>

        <div class="clause-section">
            <h2 for="owner">Rédaction de la clause bénéficiaire</h2>
            <!-- Démembrement -->
            <div class="check-container">
                <label for="dismemberment">Clause démembrée</label>
                <input type="checkbox" id="dismemberment" v-model="body.clause.dismemberment">
            </div>
            
            <!-- Rangs -->
            <AsvClauseRank
                v-for="(rank, index) in body.clause.ranks" :key="index"
                :isDismembered="body.clause.dismemberment"
                :index="index"
                :rank="rank"
                :beneficiariesList="beneficiariesList"
                @deleteRank="deleteRank(index)"
                @updateRank="(newRank) => body.clause.ranks[index] = newRank"
            />

            <div class="add-rank grow-effect" @click="addRank">+</div>

            <!-- Clause générique -->
            <div class="check-container">
                <label for="demClause">Défaut</label>
                <select id="demClause" v-model="body.clause.default">
                    <option v-for="(value, index) in defaultList" :key="index" :value="index"> {{ value }} </option>
                </select>
            </div>
            
        </div>

        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>

        <div class="clause_text" v-html="clause_text"></div>
    </form>
</template>

<script>
import axios from 'axios'
import AsvClauseRank from '../components/AsvClauseRank.vue'

export default {
    props: ['import'],
    components: {
        AsvClauseRank,
     },
    data() {
        return {
            isNew: true,
            owner: 'commun',
            errorMsg: "",
            value_update: 0,
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                label: undefined,
                type: 'assurance vie',
                owner1: undefined,
                owner2: undefined,
                opening_date: undefined,
                value: 0,
                payment_monthly: 0,
                total_payment: 0,
                payment_after70: 0,
                payment_before1998: 0,
                clause: {
                    dismemberment: false,
                    default: 1,
                    ranks: []
                },
                isIpCreation: false,
            },
            defaultList: {
                1: 'héritiers par parts égales',
                2: 'heritiers légaux par parts égales',
                3: 'héritiers au prorata de leurs droits',
                4: 'héritiers légaux au prorata de leurs droits',
            },
            clause_text: "",
        }
    },
    watch: {
        owner() {
            if (this.owner == 'commun') {
                this.body.owner1 = this.$store.getters.userId,
                this.body.owner2 = this.$store.getters.spouseId
            } else {
                this.body.owner1 = this.owner
                this.body.owner2 = null
            }
        },
        'body.clause': {
            deep: true,
            handler() {
                this.computeClauseText()
            }
        },
        'body.opening_date': function(newValue) {
            if (new Date(newValue).getTime() > new Date('1998-12-13').getTime()) {
                this.body.payment_before1998 = 0
            }
        }
    },
    methods: {
        updateValue(value) {
            this.value_update = value
        },
        submit() {
            if (this.isOriginalScenario) {
                this.body.isIpCreation = false
            } else if (this.isNew) {
                this.body.isIpCreation = true
            }

            if (this.body.type == undefined) {
                this.errorMsg = "Précisez le type de contrat"
            } else if (this.body.owner1 == undefined) {
                this.errorMsg = "Précisez le souscripteur assuré"
            } else if (this.body.clause == undefined) {
                this.errorMsg = "Précisez la clause bénéficiaire"
            } else if (this.body.total_payment == 0) {
                this.errorMsg = "Le montant total des versements ne peut pas être égal à 0"
            } else {
                this.errorMsg = ""

                if (this.isNew) {
                    if (!this.body.opening_date) {
                        this.body.opening_date = this.getCurrentDate()
                    }
                    
                    this.body.addToStore = true
                    this.$store.dispatch('create_insurance', this.body)

                } else {
                    this.body.value += this.value_update
                    if (this.value_update > 0) {
                        if (this.ownerAge >= 70) {
                            this.body.payment_after70 += this.value_update
                            this.body.total_payment += this.value_update
                        }
                    } else if (this.value_update < 0) {
                        this.body.total_payment += this.value_update

                        if (this.ownerAge >= 70) {
                            this.body.payment_after70 += this.value_update * (this.body.payment_after70 / this.body.total_payment)
                        }
                    }
                    this.$store.dispatch('action_modify_insurance', this.body)
                }

                this.$emit('closeModale')
            }
        },
        addRank() {
            this.body.clause.ranks.push({
                beneficiaries:  [],
                representation: true,
                repartition: false,
                repartition_detail: {},
                same_rank: true
            })
        },
        deleteRank(index) {

            this.body.clause.ranks.splice(index, 1)
        },
        async computeClauseText() {
            const payload = {
                contract_holder_id: this.body.owner1,
                clause: this.body.clause,
                persons: this.$store.getters.getPersons,
                couple: this.$store.getters.getContract
            }

            const clause_text = await axios.post(this.$store.getters.get_api_url + 'simulators/clauses', payload, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.clause_text = clause_text.data
        },
        updateFinancialDatas(datas) {
            this.body.entry_fees           = datas.entry_fees
            this.body.uc_management_fees   = datas.uc_management_fees
            this.body.management_mandate   = datas.management_mandate
        }
    },
    computed: {
        isOriginalScenario() {
            const routeArray = this.$route.fullPath.split('/')

            if (routeArray[3] == 'profile' || this.$route.query.isOriginal) {
                return true
            } else {
                return false
            }
        },
        ownersList() {
            let list = []
            const regmat = this.$store.getters.getContract.regmat

            if (['communauté universelle', 'communauté réduite aux acquêts', 'communauté de meubles et acquêts'].includes(regmat))
                list = [this.$store.getters.userId, this.$store.getters.spouseId, 'commun']
            else
                list = [ this.$store.getters.userId, this.$store.getters.spouseId ]

            return list
        },
        beneficiariesList() {
            if (this.body.owner1 == this.$store.getters.spouseId) {
                return this.$store.getters.findSpouseBeneficiaries
                
            } else {
                return this.$store.getters.findUserBeneficiaries
            }
        },
        ownerAge() {
            if (!this.owner) {
                return 0

            } else if (this.owner == 'commun') {
                return Math.min(this.$store.getters.getAge(this.body.owner1), this.$store.getters.getAge(this.body.owner2))

            } else {
                return this.$store.getters.getAge(this.owner)
            }
        },
        subscribedBefore1998() {
            if (new Date(this.body.opening_date).getTime() < new Date('1998-12-13').getTime()) {
                return true
            } else {
                return false
            }
        }
    },
    beforeMount() {
        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))
            this.body.opening_date = this.body.opening_date?.slice(0,10)

            if (this.body.owner1 && this.body.owner2) {
                this.owner = 'commun'
            } else {
                this.owner = this.body.owner1
            }

            this.computeClauseText()
        }

        if (this.isNew) {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    }
}
</script>

<style scoped>

.clause-section {
    display: flex;
    flex-direction: column;
}

.clause-section h2 {
    font-size: 18px;
}

.rank {
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid lightgrey;
}

.rank-label {
    padding: 5px;
    border-bottom: 1px solid lightgrey;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rank-label p {
    margin: 0;
    padding: 0;
    font-size: 13px;
}

.add-rank {
    cursor: pointer;
    text-align: center;
    background-color: orange;
    font-size: 20px;
}

.check-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    font-size: 13px;
}
.check-container select{
    flex-basis: 100%;
}

.delete-rank-btn {
    cursor: pointer;
    width: 15px;
    height: 15px;
    color: grey;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 50%;
    border: 1px grey solid;
}

.delete-rank-btn:hover {
    color: black;
    border-color: black;
}

.repartition_detail {
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.repartition {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: white;
}

.clause_text {
    background-color: rgb(238, 238, 238);
    padding: 2px 10px;
    text-align: justify;
    font-size: 16px;
}

</style>

<style src="./style.css" scoped></style>
