<template>
   <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame">
            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Acquisition du bien</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Type de biens</label>

                            <select v-model="body.property_type">
                                <option v-for="(value, key) in $store.getters.propertyTypesList" :key="key" :value="key">{{ value }}</option>
                            </select>
                        </div>

                        <div class="input-frame">
                            <label>Mode d'acquisition</label>
                            <select v-model="body.is_bought">
                                <option :value="true">Acquisition à titre onéreux</option>
                                <option :value="false">Acquisition à titre gratuit</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Prix d'acquisition</label>
                            <currency-input v-model="body.initial_value"/>
                        </div>

                        <div class="input-frame">
                            <label>Date d'acquisition</label>
                            <input type="date" v-model="body.acquisition_date">
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Frais d'acquisition (agence, notaire etc...)</label>
                            <currency-input v-model="body.acquisition_fees"/>
                        </div>

                        <div class="input-frame">
                            <label>Montant des travaux réalisés (avec justificatifs)</label>
                            <currency-input v-model="body.work_value"/>
                        </div>
                    </div>
                </div>

                <div class="section-element">
                    <h3>Vente du bien</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Prix de vente</label>
                            <currency-input v-model="body.current_value"/>
                        </div>

                        <div class="input-frame">
                            <label>Date de vente du bien</label>
                            <input type="date" v-model="body.selling_date">
                        </div>
                    </div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Résultat détaillé</h3>

                    <table>
                        <tr>
                            <th>Prix de vente</th>
                            <td>{{ toEuro(body.current_value) }}</td>
                            <td></td>
                        </tr>

                        <tr>
                            <th>Prix d'achat</th>
                            <td>- {{ toEuro(body.initial_value) }}</td>
                            <td></td>
                        </tr>

                        <tr>
                            <th>Frais d'acquisition</th>
                            <td>- {{ toEuro(computationResult.acquisition_fees) }}</td>
                            <td>
                                <span v-if="computationResult.is_fixed_acquisition_fees">Les frais d'acquisition forfaitaires de 7,5% sont appliqués.</span>
                            </td>
                        </tr>

                        <tr>
                            <th>Travaux déductibles</th>
                            <td>- {{ toEuro(computationResult.work_value) }}</td>
                            <td>
                                <span v-if="computationResult.is_fixed_work">Le forfait travaux de 15% est appliqué.</span>
                            </td>
                        </tr>

                        <tr class="highlight">
                            <th>Plus-value réalisée</th>
                            <td>= {{ toEuro(computationResult.gain) }}</td>
                            <td></td>
                        </tr>

                        <tr>
                            <th>Plus-value taxable à l'IR</th>
                            <td>{{ toEuro(computationResult.taxable_to_ir) }}</td>
                            <td>Exonération de {{ Math.round(10000 * computationResult.tax_exoneration_rate) / 100}} % après {{ computationResult.detention_duration }} ans</td>
                        </tr>

                        <tr>
                            <th>Impôt sur la plus-value</th>
                            <td>{{ toEuro(computationResult.taxes) }} </td>
                            <td>Taux forfaitaire de 19%</td>
                        </tr>

                        <tr>
                            <th>Plus-value taxable aux prélèvements sociaux</th>
                            <td>{{ toEuro(computationResult.taxable_to_social) }}</td>
                            <td>Exonération de {{ Math.round(10000 * computationResult.social_exoneration_rate) / 100}} % après {{ computationResult.detention_duration }} ans</td>
                        </tr>

                        <tr>
                            <th>Prélèvements sociaux</th>
                            <td>{{ toEuro(computationResult.social_contribution) }}</td>
                            <td>Taux forfaitaire de 17,20%</td>
                        </tr>

                        <tr>
                            <th class="result-title">Surtaxe</th>
                            <td>{{ toEuro(computationResult.additional_tax) }}</td>
                            <td>Une surtaxe est appliquée aux plus-values supérieures à 50.000€. </td>
                        </tr>

                        <tr class="highlight">
                            <th class="result-title">Total à payer</th>
                            <td>{{ toEuro(computationResult.additional_tax + computationResult.taxes + computationResult.social_contribution) }}</td>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Impôt à payer</h3>
                    <h4>{{ toEuro(computationResult.additional_tax + computationResult.taxes + computationResult.social_contribution) }}</h4>
                </div>
            </div>
        </div> 
   </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            show_detailed_result: false,
            body: {
                acquisition_date : undefined,
                property_type    : "secondary",
                is_bought        : true,
                selling_date     : undefined,
                initial_value    : 100000,
                acquisition_fees : 0,
                work_value       : 0,
                current_value    : 150000,
                selling_fees     : 0,
                is_main_property : false,
            },
            computationResult: undefined,
        }
    },
    watch: {
        'body.property_type': {
            handler() {
                if (this.body.property_type == 'main_property') {
                    this.body.is_main_property = true
                } else {
                    this.body.is_main_property = false
                }
            }
        },
        body: {
            deep: true,
            handler() {
                this.computeResult()
            }
        }
    },
    methods: {
        async computeResult() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/real-estate-gain', this.body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    }
}
</script>

<style src="./style.css" scoped></style>