<template>
    <div class="page">

        <div class="content">
            <div id="menu">
                <router-link :to="'family' + query"> <i class="material-icons">family_restroom</i> Famille</router-link>
                <router-link :to="'wealth' + query"> <i class="material-icons">account_balance</i> Patrimoine</router-link>
                <router-link :to="'dispositions' + query"> <i class="material-icons">gavel</i> Dispositions</router-link>
                <router-link :to="'taxes' + query"> <i class="material-icons">attach_money</i> Revenus & impôts</router-link>
            </div>
            
            <LiquidityIndicator v-if="!isOriginal"/>

            <router-view></router-view>
        </div>

        <SideIndicators class="side-indicator"/>
    </div>
</template>

<script>
import SideIndicators from './sections/SideIndicators.vue'
import LiquidityIndicator from './sections/LiquididyIndicator.vue'

export default {
    components: {
        LiquidityIndicator,
        SideIndicators,
    },
    data() {
        return {
            isOriginal: false,
        }
    },
    computed: {
        query() {
            return this.isOriginal ? "?isOriginal=true" : ""
        }
    },
    mounted(){
        if (this.$route.query.isOriginal) {
            this.isOriginal = true
        }
        this.$store.dispatch('importScenario', this.$route.params.scenarioId)
    }
}
</script>

<style scoped>
.page {
    display: flex;
    
}
.side-indicator {
    flex: 0 0 260px;
    width: 260px;
}
.content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

#menu {
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 120px;
    width: 50%;
}

#menu a {
    text-align: center;
    width: 25%;
    padding: 15px;
    text-decoration: none;
    white-space: nowrap;
    background-color: white;
    color: black;
    font-weight: 500;
    font-size: 18px;
    -webkit-box-shadow: 0px 0px 10px -3px #C7C7C7; 
    box-shadow: 0px 0px 10px -3px #C7C7C7;
    display: inline-flex; 
    align-items: center;
    justify-content: center;
}

#menu a i {
    margin-right: 10px;
}

#menu a:hover {
  cursor: pointer;
  font-weight: bold;
}

#menu a.router-link-active {
    background-color: #000762;
    color: white;
}

#menu a:nth-child(1){
   border-radius: 50px 0 0 50px;
   border: 1px rgb(243, 245, 247) solid;
   border-right: none;
}
#menu a:nth-child(2){
    border: 1px rgb(243, 245, 247) solid;
    border-right: none;
}
#menu a:nth-child(3){
    border: 1px rgb(243, 245, 247) solid;
    border-right: none;
}
#menu a:nth-child(4){
   border-radius: 0 50px 50px 0;
    border: 1px rgb(243, 245, 247) solid;
}


.vue-selection {
    position: absolute;
    left: 50px;
    top: 100px;
}

.vue-selection select {
    min-width: 400px;
    font-size: 20px;
    border: none;
    -webkit-box-shadow: 0px 0px 10px -3px #C7C7C7; 
    box-shadow: 0px 0px 10px -3px #C7C7C7;
}

</style>