<template>
    <div>
        

        <div class="calculator-frame" >
            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Acte notarié</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Type d'acte</label>

                            <select v-model="type" disabled>
                                <option value="donation">Acte de donation</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="section-element">
                    <h3>Informations sur la donation</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Montant de la donation réalisée</label>
                            <currency-input v-model="transmission_data.full_property_value"/>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Donation de nue-propriété</label>
                            <select v-model="transmission_data.is_dismemberment">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>

                        <div class="input-frame"  v-if="transmission_data.is_dismemberment">
                            <label>Date de naissance de l'usufruitier</label>
                            <input type="date" v-model="transmission_data.usufructuary_birthdate"/>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Donation d'un bien immobilier</label>
                            <select v-model="transmission_data.is_real_estate">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Résulat détaillé</h3>

                    <table>
                        <tr v-if="transmission_data.is_dismemberment" class="highlight">
                            <th>Valeur de la nue-propriété</th>
                            <td>{{ toEuro(computationResult.bare_property_value) }}</td>
                        </tr>

                        <tr>
                            <th>Émoluments du notaire</th>
                            <td>{{ toEuro(computationResult.notary_fees) }}</td>
                        </tr>

                        <tr>
                            <th>Taxe de publicité foncière</th>
                            <td>{{ toEuro(computationResult.land_registration_tax) }}</td>
                        </tr>

                        <tr>
                            <th>Frais d’assiette et de recouvrement</th>
                            <td>{{ toEuro(computationResult.assessment_and_recovery_cost) }}</td>
                        </tr>

                        <tr>
                            <th>Contribution sociale immobilière</th>
                            <td>{{ toEuro(computationResult.real_estate_security_contribution) }}</td>
                        </tr>

                        <tr>
                            <th>Taxe sur la Valeur Ajoutée (TVA)</th>
                            <td>{{ toEuro(computationResult.vat) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Total</th>
                            <td>{{ toEuro(computationResult.total) }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Frais de notaire</h3>
                    <h4>{{ toEuro(computationResult.total) }}</h4>
                </div>
            </div>
        </div> 
    </div>
 </template>
 
 <script>
 import axios from 'axios'
 
 export default {
     data() {
         return {
            show_detailed_result: false,

            type: 'donation',
            transmission_data: {
                full_property_value: 0,
                is_dismemberment: false,
                usufructuary_birthdate: "1960-01-01",
                is_real_estate: true,
            },

            computationResult: undefined,
         }
     },
     computed: {
         requestBody() {
             let body = {
                "type": this.type,
                "transmission_data": this.transmission_data
             }
 
             return body
         },
     },
     watch: {
         requestBody: {
             deep: true,
             handler() {
                 this.computeResult()
             }
         }
     },
     methods: {
         async computeResult() {
             const res = await axios.post(this.$store.getters.get_api_url + 'simulators/notary-fees/', this.requestBody, {
                 headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
             })
 
             this.computationResult = res.data
         }
     }
 }
 </script>
 
 <style src="./style.css" scoped></style>