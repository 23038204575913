<template>
    <div class="ifi-result-page">
        <section class="uc-real-estate-section">
            <label>Total des unités de compte représentatives de parts immobilières</label>
            <input type="number" v-model="other_immo_value">
        </section>

        <h1 class="ifi-result">{{ toEuro(ifi_result.ifi_reel) }}</h1>

        <div class="detail-title" :class="{'detail-title-rounded': !showDetail}" @click="toggleShowDetail">
            <h2>Voir le détail du calcul</h2>
        </div>

        <div class="detail-content" v-if="showDetail">
            <h3>1. Déterminer les membres du foyer fiscal IFI</h3>
            <p>Le foyer fiscal IFI diffère du foyer fiscal de l'impôt sur le revenu. Il prend en compte les deux membres d'un couple, qu'ils soient mariés, pacsés ou simplement en concubinage. Les enfants mineurs intègrent également le foyer fiscal IFI.</p>
            <p>Dans cette situation, le foyer fiscal IFI est composé de :</p>
            <ul>
                <li v-for="member in ifi_result.fiscal_ifi_members" :key="member">{{ $store.getters.findPersonName(member) }}</li>
            </ul>

            <h3>2. Calcul de la valeur IFI des parts de société</h3>

            <div v-for="(societyResult, index) in ifi_result.list_societies" :key="societyResult._id">
                <p style="margin-left: 30px;"><b>{{ index + 1 }} : {{ $store.getters.findSocietyName(societyResult._id) }}</b></p>
                <table class="balance-sheet">
                    <tr>
                        <th colspan="2">Actif brut réévalué</th>
                        <th colspan="2">Passif dû aux tiers corrigé</th>
                    </tr>

                    <tr>
                        <td>Participations</td>
                        <td>{{ toEuro(societyResult?.balance_sheet?.current_value_societies) }}</td>
                        <td>Emprunts immobiliers</td>
                        <td>{{ toEuro(societyResult?.balance_sheet?.property_debts_value) }}</td>
                    </tr>

                    <tr>
                        <td>Immobilier</td>
                        <td>{{ toEuro(societyResult?.balance_sheet?.current_value_properties) }}</td>
                        <td>Comptes courants d'associés</td>
                        <td>{{ toEuro(societyResult?.balance_sheet?.cca_value) }}</td>
                    </tr>

                    <tr>
                        <td>Autres actifs</td>
                        <td>{{ toEuro(societyResult?.balance_sheet?.current_value_others) }}</td>
                        <td>Autres dettes</td>
                        <td>{{ toEuro(societyResult?.balance_sheet?.other_debts_value) }}</td>
                    </tr>

                    <tr>
                        <th>Total</th>
                        <th>{{ toEuro(societyResult?.balance_sheet?.actif_brut) }}</th>
                        <th>Total</th>
                        <th>{{ toEuro(societyResult?.balance_sheet?.passif_total) }}</th>
                    </tr>
                </table>
                <p>La société détient un actif brut de {{ toEuro(societyResult.balance_sheet?.actif_brut) }} dont un actif immobilier brut de {{ toEuro(societyResult.actif_immobilier_brut) }}. Le coefficient immobilier est donc de {{ societyResult.actif_immobilier_brut }} / {{ societyResult.balance_sheet?.actif_brut }} = <b>{{ societyResult.coefficient * 100 }} %</b></p>
                <p>Seules les dettes ayant servi à l'acquisition ou à l'entretien d'actifs immobiliers sont déductibles. Les emprunts InFine sont amortis sur leur durée total (ou sur 20 ans en l'absence de date de fin prévue). Les dettes déductibles à l'IFI de la société sont de {{ toEuro(societyResult.passif_deductible) }}, la valeur IFI de la société est donc de {{ societyResult.balance_sheet.actif_brut }} - {{ societyResult.passif_deductible }} = <b>{{ toEuro(societyResult.society_ifi_value) }}</b></p>
                
                <p>Valeur IFI des titres : {{ toEuro(societyResult.society_ifi_value) }}</p>
                <p>Valeur taxable après application du coefficient : {{ toEuro(societyResult.theoretical_ifi_value) }}</p>
                <p>Premier plafond (Valeur vénale IFI des titres) : {{ toEuro(societyResult.market_value) }}</p>
                <p>Second plafond (Valeur de l'immobilier net de ses dettes) : {{ toEuro(societyResult.real_estate_net_value) }}</p>
                <p>Valeur à déclarer : {{ toEuro(societyResult.ifi_value) }}</p>
            </div>

            <h3>3. Calcul de l'actif immobilier</h3>
            <table>
                <tr>
                    <th>Actif immobilier</th>
                    <th>Valeur vénale</th>
                    <th>Décôte</th>
                    <th>Valeur IFI</th>
                </tr>

                <tr v-for="society in ifi_result.list_societies" :key="society._id">
                    <td>{{ $store.getters.findSocietyName(society._id) }}</td>
                    <td></td>
                    <td></td>
                    <td>{{ toEuro(society.ifi_value_owned) }}</td>
                </tr>

                <tr v-for="asset in ifi_result.list_assets" :key="asset._id">
                    <td>{{ $store.getters.propertyTypesList[asset.type] }}</td>
                    <td>{{ toEuro(asset.value) }}</td>
                    <td>{{ asset.discount * 100 }} %</td>
                    <td>{{ toEuro(asset.ifi_value) }}</td>
                </tr>

                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>{{ toEuro(ifi_result.total_taxable_asset) }}</th>
                </tr>
            </table>

            <h3>4. Calcul du passif déductible</h3>
            <p>Le capital restant dû des dettes existantes au 1er janvie et ayant servi à l'acquisition ou à l'entretien du patrimoine immobilier sont déductibles de l'IFI. Pour les emprunt non amortissables, il est appliqué un amortissement annuel. Si l'emprunt non amortissable ne prévoit pas de date de remboursement, une durée de 20 ans est appliquée pour le calcul de l'amortissement. </p>
            <p>Le montant de la dette déductible ne peut dépasser la valeur soumise à l'IFI de l'actif qu'elle a permis de financer.</p>
            <table>
                <tr>
                    <th>Dette déductible</th>
                    <th>Capital restant dû</th>
                    <th>Décôte</th>
                    <th>Valeur IFI du bien financé</th>
                    <th>Montant déductible</th>
                </tr>

                <tr v-for="debt in ifi_result.list_debts" :key="debt._id">
                    <td>{{ $store.getters.debtTypes[debt.type] }}</td>
                    <td>{{ toEuro(debt.value) }}</td>
                    <td>{{ debt.discount * 100 }} %</td>
                    <td>{{ toEuro(debt.property_ifi_value) }}</td>
                    <td>{{ toEuro(debt.ifi_value) }}</td>
                </tr>

                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>{{ toEuro(ifi_result.total_deductible_debts) }}</th>
                </tr>
            </table>

            <h3>5. Calcul de l'assiette imposable</h3>
            <p>L'assiette imposable obtenue est donc de {{ toEuro(ifi_result.total_taxable_asset) }} - {{ toEuro(ifi_result.total_deductible_debts) }} = <b>{{ toEuro(ifi_result.total_taxable_asset - ifi_result.total_deductible_debts) }}.</b></p>
            <p>Il est à noter que l'IFI est une charge déductible de sa propre base. Un premier calcul sur l'assiette précédement calculée permet d'obtenir un IFI théorique de {{ toEuro(ifi_result.ifi_theorique) }}. On obtient ainsi une assiette de <b>{{ toEuro(ifi_result.taxable - ifi_result.ifi_theorique) }}</b>.</p>

            <h3>6. Calcul de l'IFI à payer</h3>
            <table>
                <tr>
                    <th>Tranche du barème</th>
                    <th>Taux applicable</th>
                    <th>Assiette soumise à la tranche</th>
                    <th>Impôt sur la tranche</th>
                </tr>

                <tr v-for="(tranche, index) in ifi_result.ifi_used_scales" :key="index">
                    <td>{{ tranche.label }}</td>
                    <td>{{ Math.round(tranche.rate * 10000) / 100 }} %</td>
                    <td>{{ toEuro(tranche.usedScale) }}</td>
                    <td>{{ toEuro(tranche.tax) }}</td>
                </tr>

                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>{{ toEuro(ifi_result.ifi_tax_before_discount) }}</th>
                </tr>
            </table>

            <section v-if="ifi_result.ifi_discount > 0">
                <h3>7. Calcul de la décôte</h3>
                <p>Il existe une décôte applicable pour lisser l'entrée dans l'IFI. Celle-ci ne s'applique que si le patrimoine net taxable au 1er janvier se situe entre 1 300 000 € et 1 400 000 € :</p>
                <p>17 500 € - ({{ toEuro(ifi_result.taxable - ifi_result.ifi_theorique) }} x 1.25 %) = {{ toEuro(ifi_result.ifi_discount) }}</p>
                <p>L'IFI à payer par ce foyer fiscal sera donc de <b>{{ toEuro(ifi_result.ifi_reel) }}</b></p>
            </section>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            other_immo_value: 0,
            ifi_result: {},
            showDetail: true,
        }
    },
    methods: {
        async computeIfi() {
            let body = this.$store.getters.ifiDatas
            body.other_immo_value = this.other_immo_value

            const ifi_res = await axios.post(this.$store.getters.get_api_url + 'simulators/ifi-tax', body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            
            this.ifi_result = ifi_res.data
        },
        assetType(type) {
            let result = this.$store.getters.propertyTypesList[type]
            
            if (!result) {
                result = "Unités de compte immobilières"
            }

            return result
        },
        toggleShowDetail() {
            this.showDetail = !this.showDetail
        }
    },
    watch: {
        other_immo_value() {
            this.computeIfi()
        }
    },
    mounted() {
        this.computeIfi()
    }
}
</script>

<style scoped>
.ifi-result-page {
    padding: 20px;
    margin: 10px auto;
    width: 80%;
}
.uc-real-estate-section {
    width: 50%;
    display: flex;
    justify-content: space-between;
}
.uc-real-estate-section input, label {
    font-size: 22px;
}
h1.ifi-result {
    text-align: center;
    color: rgb(2, 2, 93);
    font-size: 50px;
}
.detail-title {
    cursor: pointer;
    background-color: rgb(249, 183, 59);
    color: rgb(2, 2, 93);
    padding: 5px 20px;
    border-radius: 10px 10px 0 0;
}
.detail-title-rounded {
    border-radius: 10px;
}
.detail-content {
    padding: 20px;
    border-radius: 0 0 10px 10px;
    border: 1px solid rgb(244, 183, 70)
}
.detail-content h3 {
    color: rgb(2, 2, 93);
}

table {
    width: 95%;
    margin: 10px auto;
    border-spacing : 0;
}

th, td {
    border: solid 0.5px #eee9e0;
}

th {
    padding: 10px;
    text-align: left;
    background-color: #f7efdf;
    color: rgb(92, 91, 91);
}

td {
    text-align: center;
    padding: 5px;
}

table.balance-sheet td, th {
    border: none;
    text-align: left;

}
</style>