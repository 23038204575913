import { getAge } from '../assets/functions.js'

export default {
    // Système d'erreur et de validation
    changeErrorMsg(state, msg) {
        state.errorMsg = msg
    },
    updateSelectedVue(state, selectedVue) {
        state.selectedVue = selectedVue
    },
    changeValidationMsg(state, msg) {
        state.validationMsg = msg
    },
    changeContract(state, contract) {
        // function isAlone(situation) {
        //     if (['free_union', 'pacs', 'married'].includes(situation)) {
        //         return false
        //     } else {
        //         return true
        //     }
        // }
        // const isAlonePrevious = isAlone(state.marital_contract.situation)
        // const isAloneNew = isAlone(contract.situation)

        // if (isAlonePrevious == false && isAloneNew == true) {
        //     const spouseId = state.persons.filter(p => p.role == 'spouse').map(p => p._id)[0]   
            
        //     // Suppression des liens directs
        //     let delete_direct_family = []
        //     state.persons.forEach(person => {
        //         if (person.parent1 == spouseId && !person.parent2) {
        //             delete_direct_family.push(person._id)

        //         } else if (person.parent2 == spouseId && !person.parent1) {
        //             delete_direct_family.push(person._id)

        //         } else if (person.parent1 == spouseId) {
        //             person.parent1 = undefined

        //         } else if (person.parent2 == spouseId) {
        //             person.parent2 = undefined

        //         }
        //     })

        //     // Suppression des liens indirects
        //     let delete_indirect_family = []
        //     state.persons.forEach(person => {
        //         if (delete_direct_family.includes(person.parent1)) {
        //             delete_indirect_family.push(person._id)

        //         }
        //     })

        //     state.persons = state.persons.filter(p => p.role != 'spouse')
        //     state.persons = state.persons.filter(p => !delete_direct_family.includes(p._id))
        //     state.persons = state.persons.filter(p => !delete_indirect_family.includes(p._id))

        // } else if (isAloneNew == false && isAlonePrevious == true) {
        //     state.persons.push({
        //         _id: "spouse268f7-2c29-4595-90ca-be79f09c17fb",
        //         role: "spouse",
        //         firstname: "Anonyme",
        //         lastname: "Anonyme",
        //         birthdate: "1953-01-01",
        //         is_dead: false,
        //         is_handicapped: false,
        //     })
        // }

        state.marital_contract = contract
    },
    addPerson(state, person) {
        state.persons.push(person)
    },
    addProperty(state, property) {
        state.properties.push(property)
    },
    addAsset(state, asset) {
        state.financial_assets.push(asset)
    },
    addSociety(state, society) {
        state.societies.push(society)
    },
    addMovable(state, asset) {
        state.movable_assets.push(asset)
    },
    addInsurance(state, insurance) {
        state.insurances.push(insurance)
    },
    addDebt(state, debt) {
        state.debts.push(debt)
    },
    addWill(state, will) {
        state.wills.push(will)
    },
    addGifts(state, payload) {
        payload.gifts_list.forEach(gift => {
            state.gifts.push(gift)
        })
    },
    addGift(state, gift) {
        state.gifts.push(gift)
    },
    addIncome(state, income) {
        state.incomes.push(income)
    },
    addReward(state, reward) {
        state.rewards.push(reward)
    },
    addTaxReduction(state, tax_reduction) {
        state.tax_reductions.push(tax_reduction)
    },
    addDeductibleExpense(state, deductible_expense) {
        state.deductible_expenses.push(deductible_expense)
    },
    modify(state, payload) {
        if (payload.family == 'person') {

            const personIndex = state.persons.findIndex((person) => person._id == payload.body._id)
            state.persons[personIndex] = payload.body

        } else if (payload.family == 'property') {

            const propertyIndex = state.properties.findIndex((property) => property._id == payload.body._id)
            state.properties[propertyIndex] = payload.body

        } else if (payload.family == 'asset') {
            
            const assetIndex = state.financial_assets.findIndex((asset) => asset._id == payload.body._id)
            state.financial_assets[assetIndex] = payload.body

        } else if (payload.family == 'society') {
            
            const assetIndex = state.societies.findIndex((asset) => asset._id == payload.body._id)
            state.societies[assetIndex] = payload.body

        } else if (payload.family == 'movable') {
            
            const assetIndex = state.movable_assets.findIndex((asset) => asset._id == payload.body._id)
            state.movable_assets[assetIndex] = payload.body

        } else if (payload.family == 'insurance') {
            
            const assetIndex = state.insurances.findIndex((asset) => asset._id == payload.body._id)
            state.insurances[assetIndex] = payload.body

        } else if (payload.family == 'debt') {
            
            const debtIndex = state.debts.findIndex((debt) => debt._id == payload.body._id)
            state.debts[debtIndex] = payload.body

        } else if (payload.family == 'gift') {
            
            const giftIndex = state.gifts.findIndex((gift) => gift._id == payload.body._id)
            state.gifts[giftIndex] = payload.body

        } else if (payload.family == 'will') {
            
            const willIndex = state.wills.findIndex((will) => will._id == payload.body._id)
            state.wills[willIndex] = payload.body

        } else if (payload.family == 'reward') {
            
            const rewardIndex = state.rewards.findIndex((reward) => reward._id == payload.body._id)
            state.rewards[rewardIndex] = payload.body

        } else if (payload.family == 'income') {
            
            const Index = state.incomes.findIndex((el) => el._id == payload.body._id)
            state.incomes[Index] = payload.body

        } else if (payload.family == 'tax_reduction') {
            
            const Index = state.tax_reductions.findIndex((el) => el._id == payload.body._id)
            state.tax_reductions[Index] = payload.body

        } else if (payload.family == 'deductible_expense') {
            
            const Index = state.deductible_expenses.findIndex((el) => el._id == payload.body._id)
            state.deductible_expenses[Index] = payload.body

        }
    },
    delete(state, payload) {
        let index = 0
        let toDelete = undefined

        if (payload.family == 'insurance') {
            index = 0
            state.insurances.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            state.insurances.splice(toDelete, 1);

        } else if (payload.family == 'property') {
            index = 0
            state.properties.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            
            state.properties.splice(toDelete, 1)

        } else if (payload.family == 'asset') {
            index = 0
            state.financial_assets.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            state.financial_assets.splice(toDelete, 1);

        } else if (payload.family == 'movable') {
            index = 0
            state.movable_assets.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            state.movable_assets.splice(toDelete, 1);

        } else if (payload.family == 'society') {
            index = 0
            state.societies.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            state.societies.splice(toDelete, 1);

        } else if (payload.family == 'debt') {
            index = 0
            state.debts.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            state.debts.splice(toDelete, 1);

        } else if (payload.family == 'gift') {
            index = 0
            state.gifts.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            state.gifts.splice(toDelete, 1);

        } else if (payload.family == 'will') {
            index = 0
            state.wills.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            state.wills.splice(toDelete, 1);

        } else if (payload.family == 'reward') {
            index = 0
            state.rewards.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            state.rewards.splice(toDelete, 1);

        } else if (payload.family == 'family') {
            index = 0
            state.persons.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            state.persons.splice(toDelete, 1);

        } else if (payload.family == 'tax_reduction') {
            index = 0
            state.tax_reductions.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            state.tax_reductions.splice(toDelete, 1);

        } else if (payload.family == 'deductible_expense') {
            index = 0
            state.deductible_expenses.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            state.deductible_expenses.splice(toDelete, 1);
        } else if (payload.family == 'income') {
            index = 0
            state.incomes.forEach(element => {
                if (element._id == payload.id) {
                    toDelete = index
                }

                index ++
            })
            state.incomes.splice(toDelete, 1);
        } 
    },
    updateTaxChoices(state, choices) {
        state.tax_choices = choices
    },
    // Imports des données
    importDatas(state, datas) {
        datas = JSON.parse(datas)

        let userId = undefined
        let spouseId = undefined
        datas.personResults.forEach(person => {
            if (person.type == "main") {
                userId = person.id

            } else if (person.type == "spouse") {
                spouseId = person.id
            }
        })

        
        this.commit('importMaritalContract', datas)
        this.commit('importPersons', { personResults: datas.personResults, userId: userId, spouseId: spouseId })

        let userChildrenIds = []
        let spouseChildrenIds = []

        state.persons.forEach(person => {
            if (person.role == "child" && (person.parent1 == userId || person.parent2 == userId)) {
                userChildrenIds.push(person._id)
            }

            if (person.role == "child" && (person.parent1 == spouseId || person.parent2 == spouseId)) {
                spouseChildrenIds.push(person._id)
            }
        })

        this.commit('importSocieties', { privateShareResults: datas.privateShareResults, userId: userId, spouseId: spouseId })
        this.commit('importProperties', { realEstatePropertyResults: datas.realEstatePropertyResults, userId: userId, spouseId: spouseId })
        this.commit('importFinancialAssets', { financialPropertyResults: datas.financialPropertyResults, privateShareResults: datas.privateShareResults, userId: userId, spouseId: spouseId })
        this.commit('importMovableAssets', { movablePropertyResults: datas.movablePropertyResults, userId: userId, spouseId: spouseId })
        this.commit('importInsurances', { lifeInsuranceResults: datas.lifeInsuranceResults, userId: userId, spouseId: spouseId, userChildrenIds: userChildrenIds, spouseChildrenIds: spouseChildrenIds })
        this.commit('importGifts', { donationPropertyResults: datas.donationPropertyResults, userId: userId, spouseId: spouseId })
        this.commit('importDebts', { debtResults: datas.debtResults, userId: userId, spouseId: spouseId })

        state.wills = []
    },
    importMaritalContract(state, payload) {

        const TRAD_SITUATION = {
            married: 'married',
            pacs: 'pacs',
            cohabitation: 'free_union',
            single: 'alone',
            widowed: "widowed",
            divorced: 'alone',
        }
        const TRAD_REGMAT = {
            separate: "separation",
            acquest: "aquest_community",
            universal: "universal_community",
            acquest_participation: "acquest_participation",
            acquisition: "aquest_movable_community",
            null: undefined,
        }
        
        state.marital_contract = {
            situation: TRAD_SITUATION[payload.couple.maritalStatus],
            regmat: TRAD_REGMAT[payload.couple.matrimonialSystem],
            full_attribution: payload.couple.fullAttribution,
            preciput: payload.couple.preciput,
            acquest_society: payload.couple.acquestSociety,
            inegal_repartition: payload.couple.inegalRepartition,
            inegal_repartition_detail: {},
            preciput_detail: payload.couple.couplePreciputDetails,
        }

        payload.personResults.forEach(person => {
            if (person.type == 'main') {
                state.marital_contract.ddv_userforspouse = person.partnerDonation
                state.marital_contract.ddv_spouseforuser = person.partnerDonation
            }
        })
    },
    importPersons(state, payload) {
        state.persons = []
        payload.personResults.forEach(person => {
            let role = undefined
            if (person.type == "main") {
                role = "user"

            } else if (person.type == "spouse") {
                role = "spouse"

            } else if (person.type == "children") {
                role = "child"
                
            } else if (person.type == "grandchildren") {
                role = "grandchild"
                
            } else if (person.type == "nephews") {
                role = "nephew"
                
            } else if (person.type == "siblings") {
                role = "sibling"
                
            } else if (person.type == "parents") {
                role = "parent"
                
            } else {
                role = "other"
            }

            // Infos générales
            let newPerson = {
                _id: person.id,
                civility: person.civility,
                role: role,
                firstname: person.firstname,
                lastname: person.lastname,
                birthdate: undefined,
                has_last_living_donation: false,
                has_will: false,
                will_detail: {
                    type: undefined,
                    univeral_inheritors: [],
                    sharing_detail: null
                },
                is_dead: person.isDead,
                is_handicapped: person.isHandicaped,
                is_veteran: false,
                lives_at_home: undefined,
                is_student: undefined,
                education_level: undefined,
                is_unemployed: undefined,
                is_attached: undefined,
                situation: undefined
            }

            // date de naissance 
            const bd_array = person.birthdate.split('-')
            const year = bd_array[0]
            const month = bd_array[1]
            const day = bd_array[2].substring(0, 2)

            newPerson.birthdate = [year, month, day].join('-')

            // liens familiaux 
            if (['nephew', 'grandchild'].includes(role)) {
                newPerson.parent1 = person.via_person_id

            } else {
                if (person.linkType == 'common') {
                    newPerson.parent1 = payload.userId
                    newPerson.parent2 = payload.spouseId
    
                } else if (person.linkType == 'main') {
                    newPerson.parent1 = payload.userId
    
                } else {
                    newPerson.parent1 = payload.spouseId
                }
            }

            // informations fiscales
            if (newPerson.role == 'child') {
                // calcul de l'age
                newPerson.situation = "single"

                const age = getAge(newPerson.birthdate)
                if (age < 23) {
                    newPerson.is_attached = true
                    newPerson.lives_at_home = true
                    newPerson.is_student = true
                } 
                
                if (age < 7) {
                    newPerson.education_level = "EM"
                } else if (age < 12) {
                    newPerson.education_level = "EP"
                } else if (age < 16) {
                    newPerson.education_level = "C"
                } else if (age < 18) {
                    newPerson.education_level = "L"
                } else if (age < 23) {
                    newPerson.education_level = "ES"
                }
            }

            state.persons.push(newPerson)
        })
    },
    importProperties(state, payload) {
        state.properties = []

        const RIGHTS = {
            full: 'pp',
            bare: 'np',
            usufruct: 'us'
        }

        const TYPES = {
            main_property: 'main_property',
            rental_investment: 'location',
            secondary_property: 'secondary',
            professional: 'office',
            scpi: 'scpi',
            sci: 'sci',
            parking: 'parking',
            forest: 'forest',
            field: 'land',
            gfa: 'gfa',
            gff: 'gff',
            gfv: 'gfv',
        }
        
        function findCategory(type) {
            const CATEGORIES = {
                residential: ['main_property', 'secondary_property'],
                yield: ['rental_investment', 'parking', 'forest', 'field', 'gfa', 'gff', 'gfv'],
                pro: ['professional'],
            }

            let category = 'residential'
            Object.keys(CATEGORIES).forEach(cat => {
                if (CATEGORIES[cat].includes(type)) {
                    category = cat
                }
            })

            return category
        }
        
        payload.realEstatePropertyResults.forEach(property => {
            if (property.usage == 'sci') {
                let newAsset = {
                    _id: property.id,
                    category: "society",
                    label: "SCI",
                    type: 'sci',
                    value: property.value,
                    isIndivision: true,
                    detention: [],
                }

                // Propriétaire
                if (property.owner == 'owner') {
                    newAsset.detention.push({ owner_id: payload.userId, right: RIGHTS[property.propertyRight], part: 1 })

                } else if (property.owner == 'spouse') {
                    newAsset.detention.push({ owner_id: payload.spouseId, right: RIGHTS[property.propertyRight], part: 1 })

                } else {
                    newAsset.detention.push({ owner_id: payload.userId, right: RIGHTS[property.propertyRight], part: 0.5 })
                    newAsset.detention.push({ owner_id: payload.spouseId, right: RIGHTS[property.propertyRight], part: 0.5 })
                }

                state.societies.push(newAsset)
            } else {
                // Infos générales
                let newProperty = {
                    _id: property.id,
                    category: findCategory(property.usage),
                    type: TYPES[property.usage],
                    value: property.value,
                    isIndivision: false,
                    detention: [],
                }

                // Indivision
                if (state.marital_contract.regmat == 'separation' && !state.marital_contract.acquest_society) {
                    newProperty.isIndivision = true
                }

                // Propriétaire
                if (property.propertyMode == 'indivision') {
                    newProperty.isIndivision = true

                    if (property.propertyPercentage > 0) {
                        newProperty.detention.push({ owner_id: payload.userId, right: RIGHTS[property.propertyRight], part: property.propertyPercentage / 100 })
                    }

                    if (property.propertyPercentageSpouse > 0) {
                        newProperty.detention.push({ owner_id: payload.spouseId, right: RIGHTS[property.propertyRight], part: property.propertyPercentageSpouse / 100 })
                    } 

                } else if (property.owner == 'owner') {
                    newProperty.detention.push({ owner_id: payload.userId, right: RIGHTS[property.propertyRight], part: 1 })

                } else if (property.owner == 'spouse') {
                    newProperty.detention.push({ owner_id: payload.spouseId, right: RIGHTS[property.propertyRight], part: 1 })

                } else {
                    newProperty.detention.push({ owner_id: payload.userId, right: RIGHTS[property.propertyRight], part: 0.5 })
                    newProperty.detention.push({ owner_id: payload.spouseId, right: RIGHTS[property.propertyRight], part: 0.5 })
                }

                // Type
                if (newProperty.type == undefined) {
                    newProperty.type = "Autre"
                }
                
                state.properties.push(newProperty)
            }
        })
    },
    importFinancialAssets(state, payload) {
        state.financial_assets = []

        const RIGHTS = {
            full: 'pp',
            bare: 'np',
            usufruct: 'us'
        }

        const TYPES = {
            cc: 'cc',
            saving_account: 'csl',
            livret_a: 'livreta',
            livret_b: 'csl',
            cel: 'cel',
            ldd: 'ldds',
            pel: 'pel',
            lep: 'lep',
            pep: 'cto',
            livret_jeune: 'csl',
            perp: 'perin',
            pee: 'pee',
            pea: 'pea',
            madelin: 'perin',
            social_share: 'social_share',
            share_account: 'cto',
            capitalization_contract: 'capi',
            per_in: 'perin',
            fcpi: 'fcpi',
            fip: 'fip',
            other: 'other',
            individual: 'ei',
            goodwill: 'goodwill',
        }

        // Catégories 

        const types = {
            bank: {
                cc: 'Compte courant',
                livreta: 'Livret A',
                ldds: "LDDS",
                csl: "Compte sur livret",
                cat: "Compte à terme",
                capi: "Contrat de capitalisation",
                pel: "PEL",
                cel: "CEL",
                lep: "LEP",
                pea: "PEA",
                cto: "CTO",
                social_share: 'Parts sociales bancaires',
                other: "Autre"
            },
            stock: {
                goodwill: "Fonds de commerce",
                ei: "Entreprise individuelle",
                pee: "PEE"
            },
            retirement: {
                perin: "PER compte-titre",
                percol: "PER Collectif bancaire",
                pero: "PER Obligatoire bancaire",
                perco: "Plan d'Épargne Retraite Collectif"

            },
            fiscal_fund: {
                fcpi: "FCPI",
                fip: "FIP",
                fcpr: "FCPR",
                fpci: "FPCI",
            }
        }
        const bank = Object.keys(types.bank)
        const stock = Object.keys(types.stock)
        const retirement = Object.keys(types.retirement)
        const fiscal_fund = Object.keys(types.fiscal_fund)
        
        payload.financialPropertyResults.forEach(asset => {
            // Infos générales
            let newAsset = {
                _id: asset.id,
                category: "bank",
                type: TYPES[asset.type],
                value: asset.value,
                payment: 0,
                isIndivision: false,
                detention: [],
            }

            // Type
            if (newAsset.type == undefined) {
                newAsset.type = "other"
            }

            // Catégorie
            if (bank.includes(newAsset.type)) {
                newAsset.category = 'bank'

            } else if (stock.includes(newAsset.type)) {
                newAsset.category = 'stock'

            } else if (retirement.includes(newAsset.type)) {
                newAsset.category = 'retirement'
                
            } else if (fiscal_fund.includes(newAsset.type)) {
                newAsset.category = 'fiscal_fund'
                
            } else {
                newAsset.category = 'bank'
            }
            
            // Propriétaire
            if (asset.owner == 'owner') {
                newAsset.detention.push({ owner_id: payload.userId, right: RIGHTS[asset.propertyRight], part: 1 })

            } else if (asset.owner == 'spouse') {
                newAsset.detention.push({ owner_id: payload.spouseId, right: RIGHTS[asset.propertyRight], part: 1 })

            } else {
                newAsset.detention.push({ owner_id: payload.userId, right: RIGHTS[asset.propertyRight], part: 0.5 })
                newAsset.detention.push({ owner_id: payload.spouseId, right: RIGHTS[asset.propertyRight], part: 0.5 })
            }

            state.financial_assets.push(newAsset)
        })
    },
    importSocieties(state, payload) {
        state.societies = []

        const RIGHTS = {
            full: 'pp',
            bare: 'np',
            usufruct: 'us'
        }

        const TYPES = {
            sasu: 'sasu',
            snc: 'snc',
            sa: 'sa',
            selarl: 'selarl',
            scp: 'scp',
            sas: 'sas',
            sarl: "sarl"
        }

        payload.privateShareResults.forEach(asset => {

            // Infos générales
            let newAsset = {
                _id: asset.id,
                category: "society",
                type: TYPES[asset.type],
                label: 'ENTREPRISE',
                value: asset.value,
                isIndivision: true,
                detention: [],
            }

            // type
            if (!newAsset.type) {
                newAsset.type = 'sas'
            }

            // Propriétaire
            if (asset.owner == 'owner') {
                newAsset.detention.push({ owner_id: payload.userId, right: RIGHTS[asset.propertyRight], part: 1 })

            } else if (asset.owner == 'spouse') {
                newAsset.detention.push({ owner_id: payload.spouseId, right: RIGHTS[asset.propertyRight], part: 1 })

            } else {
                newAsset.detention.push({ owner_id: payload.userId, right: RIGHTS[asset.propertyRight], part: 0.5 })
                newAsset.detention.push({ owner_id: payload.spouseId, right: RIGHTS[asset.propertyRight], part: 0.5 })
            }

            state.societies.push(newAsset)
        })
    },
    importMovableAssets(state, payload) {
        state.movable_assets = []

        const RIGHTS = {
            full: 'pp',
            bare: 'np',
            usufruct: 'us'
        }

        const TYPES = {
            furniture: "furnitures",
            vehicle: "car",
            jelewry: "precious_stones",
            art_piece: "precious_metals",
            gem_stone: "precious_stones",
            money_piece: "precious_metals",
            artistic_furniture: "furnitures",
            horse: "horse",
            boat: "boat",
            plane: "plane",
            other: "other"
        }
        
        payload.movablePropertyResults.forEach(asset => {

            // Infos générales
            let newAsset = {
                _id: asset.id,
                type: TYPES[asset.type],
                value: asset.value,
                isIndivision: false,
                detention: [],
            }

            // Type
            if (newAsset.type == undefined) {
                newAsset.type = "other"
            }

            // Propriétaire
            if (asset.owner == 'owner') {
                newAsset.detention.push({ owner_id: payload.userId, right: RIGHTS[asset.propertyRight], part: 1 })

            } else if (asset.owner == 'spouse') {
                newAsset.detention.push({ owner_id: payload.spouseId, right: RIGHTS[asset.propertyRight], part: 1 })

            } else {
                newAsset.detention.push({ owner_id: payload.userId, right: RIGHTS[asset.propertyRight], part: 0.5 })
                newAsset.detention.push({ owner_id: payload.spouseId, right: RIGHTS[asset.propertyRight], part: 0.5 })
            }

            state.movable_assets.push(newAsset)
        })
    },
    importInsurances(state, payload) {
        state.insurances = []

        const TYPES = {
            classical: 'life',
            madelin: 'madelin',
            per_in: 'perin',
            per_col: 'percol',
            per_cat: 'pero',
            perp : 'perp'
        }
        
        payload.lifeInsuranceResults.forEach(asset => {
            // Infos générales
            let newAsset = {
                _id: asset.id,
                type: TYPES[asset.type],
                owner1: undefined,
                owner2: undefined,
                opening_date: asset.openingDate,
                value: asset.value,
                total_payment: asset.totalDeposit,
                payment_after70: asset.depositAfter70,
                payment_before1998: asset.valueBefore1998,
            }

            // Type
            if (newAsset.type == undefined) {
                newAsset.type = "classical"
            }
            
            newAsset.clause = {
                dismemberment : asset.dismemberment,
                default : 1,
                ranks: []
            }

            asset.ranks.forEach(rank => {
                let bList = []
                let repDetail = {}

                rank.beneficiaries.forEach(b => {
                    bList.push(b.person)
                    repDetail[b.person] = b.percentage
                })

                newAsset.clause.ranks.push({
                    beneficiaries: bList,
                    representation: rank.representation,
                    repartition: rank.repartition,
                    order: rank.order,
                    same_rank: false, 
                    repartition_detail: repDetail
                })
            })

            // Propriétaire
            if (asset.owner == 'common') {
                newAsset.owner1 = payload.userId
                newAsset.owner2 = payload.spouseId

            } else if (asset.owner == "owner") {
                newAsset.owner1 = payload.userId

            } else {
                newAsset.owner1 = payload.spouseId
            }

            state.insurances.push(newAsset)
        })
    },
    importGifts(state, payload) {
        state.gifts = []

        const TYPES = {
            realestate  : "property",
            manual      : 'manual',
            liquidity   : 'liquidity',
            dutreil     : 'dutreil',
            financial   : "financial",
            simple      : "manual",
            shared      : "manual",
        }
        
        payload.donationPropertyResults.forEach(gift => {

            // Infos générales
            let newGift = {
                _id: gift.id,
                donor: undefined,
                donee: gift.receiver.id,
                type: TYPES[gift.type],
                value: gift.civilValue,
                fiscal_value: gift.value,
                date: gift.date.slice(0, 10),
                aps: gift.isPresuccession,
                is_shared: gift.isShared,
                is_dismembered: false
            }
            

            // Type
            if (newGift.type == undefined) {
                newGift.type = "other"
            }

            // partage
            if (gift.type == 'shared') {
                // donations provenants de MS
                newGift.is_shared = true
                newGift.aps = true
            }

            // Démembrement
            if (gift.propertyRightDonation == 'bare') {
                newGift.is_dismembered = true
            }

            // Propriétaire
            if (gift.owner == "owner") {
                newGift.donor = payload.userId
                state.gifts.push(newGift)

            } else if (gift.owner == "spouse") {
                newGift.donor = payload.spouseId
                state.gifts.push(newGift)

            } else if (gift.owner == 'common') {
                // user
                newGift.value        = newGift.value / 2
                newGift.fiscal_value = newGift.fiscal_value / 2
                newGift.donor = payload.userId
                state.gifts.push(newGift)

                // spouse
                let newGiftSpouse = JSON.parse(JSON.stringify(newGift))
                newGiftSpouse.donor = payload.spouseId
                state.gifts.push(newGiftSpouse)
            }
        })
    },
    importDebts(state, payload) {
        state.debts = []

        const TYPES = {
            uninsured_loan: "uninsured_loan",
            insured_loan: "insured_loan",
            insured_property_loan: "property_loan"
        }
        
        payload.debtResults.forEach(debt => {

            // Infos générales
            let newDebt = {
                _id: debt.id,
                type: TYPES[debt.type],
                value: debt.value,
                owner1: undefined,
                owner2: undefined,
                insurance_owner1: debt.quotityPercentage,
                insurance_owner2: debt.quotityPercentageSpouse,
                property_id: undefined,
            }

            // Type
            if (newDebt.type == undefined) {
                newDebt.type = "uninsured_loan"
            }

            // Propriétaire
            if (debt.owner == 'common') {
                newDebt.owner1 = payload.userId
                newDebt.owner2 = payload.spouseId

            } else if (debt.owner == "owner") {
                newDebt.owner1 = payload.userId

            } else {
                newDebt.owner1 = payload.spouseId
            }

            // Bien financé
            if (debt.fundedRealEstateAsset) {
                newDebt.property_id = debt.fundedRealEstateAsset.id
            }

            state.debts.push(newDebt)
        })
    },

    // AUTHENTIFICATION
    setAuth(state, payload) {
        state.auth = payload
    },
    setAdmin(state, admin) {
        state.admin = admin
    }
}